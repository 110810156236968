import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import BioPic from '../images/Bio Pic.jpg';
import '../index.css';

const Home = () => {
  const showSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const allSections = document.querySelectorAll('.section');
      allSections.forEach((sec) => {
        sec.style.display = 'none';
      });
      section.style.display = 'block';
    }
  };

  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>

        <div id="about" className="tab-content">
          <h2>About</h2>
          <div className="sidebar">
            <button onClick={() => showSection('section1')}>Short Overview</button>
            <button onClick={() => showSection('section2')}>Early Life and Background</button>
            <button onClick={() => showSection('section3')}>Academic Achievements</button>
            <button onClick={() => showSection('section4')}>Work and Career</button>
            <button onClick={() => showSection('section5')}>Contact</button>
          </div>
          <div className="content">
            <div id="section1" className="section">
              <h3 class="overview-heading">Short Overview</h3>
              <img src={BioPic} alt="Paul Paskevicius" className="image-size" />
              <div className="section-image">
                <p1>Paul Paskevicius is a finance graduate from DePaul University, class of 2022. With a background spanning operations, accounting, and research, Paul has acquired a comprehensive understanding of various industries.</p1>
                <p>However, it is Paul's fervent passion for blockchain technology that truly drives him. He firmly believes in its potential to revolutionize the financial services industry and transform enterprise technology as a whole. It is this belief that fuels his enthusiasm and dedication to exploring the endless possibilities that blockchain offers.</p>
                <p>Additionally, Paul has entrepreneurial experience, having started his own business before, and he is known as an innovator, always seeking ways to better the world.</p>
                <p>Join Paul on this exciting journey as he is always looking to meet new people, experience new adventures, and strategize on various business ventures and ideas. Together, let's unlock the potential of blockchain and shape the future of tomorrow.</p>
              </div>
            </div>
            <div id="section2" className="section" style={{ display: 'none' }}>
              <h3>Early Life and Background</h3>
              <p><strong>Lithuanian Heritage</strong></p>
              <p>Paul was born to two Lithuanian immigrant parents on September 4th, 1999. Lithuanian was his first language, and he attended 10 years of Lithuanian school.</p>
              <p><strong>Growing up in Lisle, Illinois</strong></p>
              <p>Paul spent most of his childhood in Lisle, a suburb of Chicago bordering Naperville. He holds a deep appreciation and love for the Midwest, with Chicago ranking as his favorite city.</p>
              <p><strong>Passionate About Travel</strong></p>
              <p>Coming from a family that embraced the outdoors and adventures, Paul developed a profound appreciation for travel. Having explored 40 U.S. states and visited Lithuania 7 times, he relishes in meeting new people, savoring unique cuisine, and immersing himself in diverse cultures.</p>
              <p><strong>Basketball and Fishing Enthusiast</strong></p>
              <p>Basketball, Lithuania's national sport, played a significant role in Paul's upbringing. He participated in basketball throughout his childhood, notably for Lituanica, the Lithuanian travel team based in Lemont. In high school, Paul discovered a passion for bass fishing, leading his team to multiple divisional and sectional championships.</p>
              <p><strong>Entrepreneurial Spirit</strong></p>
              <p>Driven by a fascination with business and economics from a young age, Paul embarked on various entrepreneurial endeavors. From running lemonade stands to reselling designer shoes and even attempting to launch a crypto hedge fund, he leveraged his past failures as valuable lessons for future ventures.</p>
            </div>
            <div id="section3" class="section" style={{ display: 'none' }}>
              <h3>Academic Achievements</h3>
              <p><strong>Napervile North High School (2014-2018)</strong></p>
              <p>• Overall GPA: 3.80</p>
              <p>• Accounting Club (2016-2017)</p>
              <p>• Bass Fishing Team - JV (2016-2017) and Varsity (2017-2018)</p>
              <p><strong>DePaul University - Bachelor of Science in Business - Finance (2018-2022)</strong></p>
              <p>•  Overall GPA: 3.50</p>
              <p>•  Finance Major GPA: 4.00</p>
              <p>•	Delta Sigma Pi Professional Business Fraternity - VP of Finance</p>
              <p>•	CFA Research Challenge - Team Leader - 3rd place in Chicago Sub-Regional Competition</p>
              <p>•	DePaul University Fishing Club - Founder and President</p>
              <p>•	DePaul Crypto Club - Founder and President</p>
              <p>•	Vincentian Service Scholarship Cohort - Scholar</p>
              <p>•	Habitat for Humanity - Member</p>
            </div>
            <div id="section4" class="section" style={{ display: 'none'}}>
              <h3>Work and Career</h3>
              <p><strong>HFR Asset Management - Operations Analyst (2022-2024)</strong></p>
              <p>•	Developed and implemented a Python GUI to streamline and optimize the wires and FX instruction process, enhancing efficiency and reducing manual errors.</p>
              <p>•	Designed and deployed a SQL database to track and analyze wires and FX activity, providing comprehensive transaction history and facilitating data-driven decision-making.</p>
              <p>•	Spearheaded automation efforts by creating a Python script to seamlessly retrieve broker statements from email inboxes and store them directly on OneDrive, significantly improving accessibility and data management workflows.</p>
              <p>•	Implemented AWS server for FTP statement retrieval via Filezilla, while automating additional tasks such as bank holiday reminders and monitoring cash account overdraft balances, enhancing operational efficiency and timely financial management.</p>
              <p><strong>Taiga Group Holdings, LLC - Founder and CEO (2021-2023)</strong></p>
              <p>•	Developed a delta neutral liquidity pool trading strategy on Uniswap V2 and V3.</p>
              <p>•	Built an algorithm for Uniswap to facilitate pool creation, liquidity adjustments, position closure, and fee collection.</p>
              <p>•	Created JavaScript scripts to extract data and utilized Python for in-depth analysis of trading activity and liquidity trends, identifying lucrative opportunities.</p>
              <p><strong>HFR Asset Management - Cryptocurrency Analyst Intern (2021-2022)</strong></p>
              <p>•	Conducted in-depth research on various cryptocurrencies and DeFi platforms, analyzing market trends, technological developments, and investment opportunities to inform strategic decision-making.</p>
              <p>•	Presented comprehensive investment analysis and insights on cryptocurrency markets to the executive team, facilitating informed discussions and guiding investment strategies for optimal returns.</p>
              <p><strong>Driehaus Center for Behavioral Finance - Student Worker (2021-2022)</strong></p>
              <p>•	Collaborated with the advisory board to compile quarterly reports, develop meeting agendas, and facilitate discussions, providing insights and addressing inquiries.</p>
              <p>•	Initiated a marketing project by crafting 26 email posts addressing diverse behavioral finance biases, effects, and aversions, utilizing Constant Contact platform, and contributing to increased engagement and awareness.</p>
              <p>•	Assisted in updating the DCBF catalog, encompassing a range of academic research papers, reports, and presentations focused on behavioral finance, ensuring accuracy and accessibility for stakeholders.</p>
              <p><strong>YMY Solutions - Research Analyst Intern (2021-2021)</strong></p>
              <p>•	Selected as one of 10 students from over 30 candidates into the 2021 Internship Cohort Program.</p>
              <p>•	Conducted macro market research, prepared qualitative and quantitative reports, and pitched stock recommendations using YMY’s proprietary frameworks and tools.</p>
              <p>•	Received the highest rating of all the interns on my financial presentation in front of the company and was selected to present my thesis in front of institutional clients, C-Suite executives, and other financial professionals.</p>
              <p><strong>VelocityEHS - Accounting Intern (2019-2021)</strong></p>
              <p>• Spearheaded the implementation of streamlined invoicing processes, transitioning from mailing to emailing invoices, enhancing efficiency and reducing costs.</p>
              <p>• Recognized for rapid mastery of accounting software and practices, entrusted with training fellow interns and optimizing team productivity.</p>
              <p>• Led the preparation of revenue recognition and payment support for auditors, significantly reducing managerial workload and improving audit efficiency.</p>
            </div>

            <div id="section5" class="section" style={{ display: 'none' }}>
              <h3>Contact</h3>
              <p><strong>Email:</strong> paulpaskevicius@gmail.com</p>
              <p><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/paul-paskevicius/">https://www.linkedin.com/in/paul-paskevicius/</a></p>
            </div>
                </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home.js';
import NotFound from './components/NotFound.js';
import Research from './components/research.js';
import Bookcase from './components/bookcase.js';
import Playground from './components/playground/playground.js';
import Blockchain from './components/playground/blockchain/blockchain.js';
import Cryptography from './components/playground/blockchain/cryptography.js';
import PublicKeyCryptography from './components/playground/blockchain/public-key-cryptography.js';
import BlockchainSummary from './components/playground/blockchain/blockchain-summary.js';
import DataEE from './components/playground/dataE&E/data-encryption-and-exchange.js';
import DataEEexample from './components/playground/dataE&E/example.js';
import DataEEsummary from './components/playground/dataE&E/summary.js';
import DecentralizedNetwork from './components/playground/decentralized-network/network.js';
import DecentralizedNetworkExample from './components/playground/decentralized-network/example.js';
import ConsensusMechanisms from './components/playground/consensus-mechanisms/consensus-mechanisms.js';
import ProofOfWork from './components/playground/consensus-mechanisms/proof-of-work.js';
import ProofOfWorkExample from './components/playground/consensus-mechanisms/proof-of-work-example.js';
import ProofOfWorkSummary from './components/playground/consensus-mechanisms/proof-of-work-summary';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} /> {/* Catch all routes that don't match */}
        <Route path="/research" element={<Research />} />
        <Route path="/bookcase" element={<Bookcase />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="/playground/blockchain" element={<Blockchain />} />
        <Route path="/playground/blockchain/cryptography" element={<Cryptography />} />
        <Route path="/playground/blockchain/cryptography/public-key-cryptography" element={<PublicKeyCryptography />} />
        <Route path="/playground/blockchain/summary" element={<BlockchainSummary />} />
        <Route path="/playground/data-encryption-and-exchange/" element={<DataEE />} />
        <Route path="/playground/data-encryption-and-exchange/example" element={<DataEEexample />} />
        <Route path="/playground/data-encryption-and-exchange/summary" element={<DataEEsummary />} />
        <Route path="/playground/decentralized-network" element={<DecentralizedNetwork />} />
        <Route path="/playground/decentralized-network/example" element={<DecentralizedNetworkExample />} />
        <Route path="/playground/consensus-mechanisms" element={<ConsensusMechanisms />} />
        <Route path="/playground/consensus-mechanisms/proof-of-work" element={<ProofOfWork />} />
        <Route path="/playground/consensus-mechanisms/proof-of-work-example" element={<ProofOfWorkExample />} />
        <Route path="/playground/consensus-mechanisms/proof-of-work-summary" element={<ProofOfWorkSummary />} />

      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>How is Cryptography Used to Encrypt and Exchange Data?</h1>
          <p>In the previous section we learned about public and private keys and how their encryption prevents brute force attacks due to high computation resources and costs.</p>
          <p>In a similar way that we generated those two keys, we can now use those keys to encrypt specific pieces of data like a message, transaction details, bank information, etc.</p>
          <p>Once we encrypt that data we can then exchange it with who we want to transact with and we can prevent unauthorized access to our data.</p>
          <p>Let's go to the example!</p>
          <Link to="/playground/data-encryption-and-exchange/example">
          <button>Next: Data Encryption Example</button>
            </Link>      
        </div>

    </div>

    );
};

export default Playground;
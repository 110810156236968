import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const isPrime = (num) => {
  if (num <= 1) return false;
  if (num <= 3) return true;
  if (num % 2 === 0 || num % 3 === 0) return false;
  let i = 5;
  while (i * i <= num) {
    if (num % i === 0 || num % (i + 2) === 0) return false;
    i += 6;
  }
  return true;
};

const Playground = () => {
  const [boxValues, setBoxValues] = useState({
    box1: '',
    box2: '',
    box3: '',
    box4: '',
    box5: '',
    box6: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [combinations, setCombinations] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBoxValues({
      ...boxValues,
      [name]: value
    });
  };

  const multiplyNumbers = () => {
    const { box1, box2, box3, box4, box5 } = boxValues;
    const inputs = [parseInt(box1), parseInt(box2), parseInt(box3), parseInt(box4), parseInt(box5)];

    if (inputs.some(input => !isPrime(input))) {
      setErrorMessage('Please enter prime numbers only.');
      return;
    }

    const result = inputs.reduce((acc, curr) => acc * curr, 1);
    setBoxValues({
      ...boxValues,
      box6: result
    });
    setErrorMessage('');

    // Calculate the number of combinations
    const numberOfCombinations = Math.pow(2, inputs.length) - 1; // Exclude the case where all numbers are excluded
    console.log("numberOfCombinations:", numberOfCombinations); // Print numberOfCombinations to console
    setCombinations(numberOfCombinations);
  };

  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>What is Public-key Cryptography?</h1>
          <p>Public-key cryptography, also known as asymmetric cryptography, is a cryptographic approach that uses a pair of keys for secure communication: a public key and a private key.</p>
          <p>Let's use an interactive and simplified example to create a public and private key.</p>
          <p>To achieve this we must use math. In our example, we will use prime number multiplication.</p>
          <p><i>Note: A prime number is a whole number greater than 1 that cannot be exactly divided by any whole number other than itself and 1</i></p>
          <p>Come up with 5 prime numbers to multiply together for our example:</p>
          <div>
            <input type="number" name="box1" value={boxValues.box1} onChange={handleInputChange} />
            <span> X </span>
            <input type="number" name="box2" value={boxValues.box2} onChange={handleInputChange} />
            <span> X </span>
            <input type="number" name="box3" value={boxValues.box3} onChange={handleInputChange} />
            <span> X </span>
            <input type="number" name="box4" value={boxValues.box4} onChange={handleInputChange} />
            <span> X </span>
            <input type="number" name="box5" value={boxValues.box5} onChange={handleInputChange} />
            <span> = </span>
            <input type="text" name="box6" value={boxValues.box6} readOnly />
          </div>

          {errorMessage && <p className="error">{errorMessage}</p>}
          <p></p>
          <button onClick={multiplyNumbers}>Calculate</button>
          <p>So, this is now our public key:</p>
          <div><input type="text" name="box6" value={boxValues.box6} readOnly /></div>
          <p>And our private key is the 5 prime numbers we multiplied together to get our public key.</p>
          <p>Now, why is this secure?</p>
          <p>Because there are a lot of combinations of prime numbers that can me multiplied to calculate our public key:</p>
          <p><u>Number of combinations:</u> {combinations}</p>
          <p>With this many combinations it would be hard to brute force the private key by hand but with a computer the calculation could be done quickly.</p>
          <p>However, imagine a much larger scale, what if we multiplied 256 prime numbers instead of 5?</p>
          <p>Well, to calculate the private key with brute force would be very computationally difficult and expensive resulting in a secure encryption.</p>
          <p>Now let's take what we learned about cryptography and put it all together.</p>

          <Link to="/playground/blockchain/summary">
            <button>Next: Blockchain Summary</button>
          </Link>
      </div>
    </div>
  );
};

export default Playground;

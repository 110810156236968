import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';
import venmoEx1 from '../../../images/venmoEx1.jpg';
import venmoEx2 from '../../../images/venmoEx2.jpg';
import p2pEx1 from '../../../images/p2pEx1.jpg';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>Decentralized Network Example</h1>
          <p>On a daily basis we use an intermediary for our data exchange. From emails, to text messages to Venmo payments we are constantly using an intermediary.</p>
          <p>And the reason for this is valid. These providers create a product that we can just use. We don't need to code, we don't need to build anything or maintain any hardware.</p>
          <p>In fact, most of these applications are free and we just watch ads or give up our consumer data.</p>
          <p>For our example, let's look at a very simple high-level overview of a payment being sent using Venmo:</p>
          <img src={venmoEx1} alt="Venmo Example 1"/>
          <p>It is very straightforward and then the architecture at Venmo would look something like this:</p>
          <img src={venmoEx2} alt="Venmo Example 2"/>
          <p>Again this is simplified but the idea is that Venmo would handle all the cost activities and the user simply uses their app for free and either pays transaction fees or watches ads or something similar.</p>
          <p>Now let's take a look at a decentralized example of this:</p>
          <img src={p2pEx1} alt="Decentralized P2P Example"/>
          <p>Very simple. A real life example of this would be a cash transaction where we just exchange dollars in person.</p>
          <p>Now let's imagine we did build a simple app and created our own currency called BestBuddyz.</p>
          <p>There would be costs to maintain an application and so we would probably need to add new members to our network to justify running it.</p>
          <p>This is where we would hit the pivotal crossroads of centralized vs decentralized solution.</p>
          <p>If we start gaining users and decide to maintain all cost activities in hopes of other users paying us then we now have a centralized network.</p>
          <p>To build a truly decentralized application we would need to allow our users to have a say in decision making, provide their own cost activities and incentivize usage.</p>
          <p>These are the same fundamental questions that Satoshi was asking when he built Bitcoin.</p>
          <p>Let's start diving deep into the solutions to these problems and let's start with the most important one:</p>
          <p>Consensus Mechanisms: The protocols used to achieve agreement among distributed network participants</p>
          <Link to="/playground/">
          <button>Return to Playground</button>
          </Link>  
          <p></p>
          <Link to="/playground/consensus-mechanisms">
            <button style={{ marginBottom: '100px' }}>Continue to: Consensus Mechanisms</button>
            </Link>
        </div>

    </div>

    );
};

export default Playground;
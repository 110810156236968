import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {
  const [blocks, setBlocks] = useState([
    {
      nonce: 644312,
      hash: "000015783b764259d382017d91a36d206d0600e2cbb3567748f46a33fe9297cf",
      proofOfWorkStatus: "Valid",
      timestamp: "January 1, 2024 12:00:00 UTC"
    }
  ]);

  const mineBlock = () => {
    const previousBlock = blocks[blocks.length - 1]; // Get the previous block
    const newNonce = Math.floor(Math.random() * 1000000); // Generate a random nonce
    const previousHash = previousBlock.hash; // Get the previous hash
    const transactions = "[transaction data here]"; // Get the transactions
    const blockHeader = `Block #${blocks.length + 1}`; // Generate the block header
    const timestamp = new Date().toUTCString(); // Generate the timestamp
  
    // Placeholder function for hash calculation (replace with your actual hashing algorithm)
    const newHash = calculateHash(newNonce, previousHash, transactions, blockHeader);
  
    // Update state with the new block
    setBlocks(prevBlocks => [
      ...prevBlocks,
      {
        nonce: newNonce,
        hash: newHash,
        proofOfWorkStatus: "Mined",
        timestamp: timestamp // Include the timestamp in the new block
      }
    ]);
  };
  

  const generateRandomHash = () => {
    const characters = 'abcdef0123456789';
    let randomHash = '';
    for (let i = 0; i < 64; i++) {
      randomHash += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomHash;
  };
  

  // Placeholder function for hash calculation (replace with your actual hashing algorithm)
  const calculateHash = (nonce, previousHash, transactions, blockHeader) => {
    // Placeholder function for hash calculation (replace with your actual hashing algorithm)
    return generateRandomHash(); // Example hash calculation
  };

  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
        <h1>Proof of Work Example</h1>
        <p>Click the "Mine" button below to simulate the creation of a block into a blockchain.</p>
        {blocks.map((block, index) => (
          <div className="block" key={index}>
            <div className="block-header">Block #{index + 1}</div>
            <div className="block-content">
              <p>Previous Hash: <span className="hash-value">{index === 0 ? "0000000000000000000000000000000000000000000000000000000000000000" : blocks[index - 1].hash}</span></p>
              <p>Transactions: [transaction data here]</p>
              <p>Nonce: <span className="nonce">{block.nonce}</span></p>
              <p>Hash: <span className="hash-value">{block.hash}</span></p>
              <p className="proof-success">{block.proofOfWorkStatus}</p>
            </div>
            <div className="block-footer">Timestamp: {block.timestamp}</div>
          </div>
        ))}
        <button onClick={mineBlock}>Mine</button>
        <p>As you can tell, this is a simple algorithm that requires minimal computational effort.</p>
        <p>In the early days of Bitcoin, you could mine this puzzle with your phone.</p>
        <p>Now you need thousands of dollars invested into ASIC mining equipment.</p>
        <p>You can also notice that each block has its own nonce and that the new block must have the previous block's hash function to be valid.</p>
        <p>You can also see where the transaction data placeholder is. In a real example there would be transactions here.</p>
        <p>Now, let's go summarize what we have learned.</p>
        <Link to="/playground/consensus-mechanisms/proof-of-work-summary">
          <button style={{ marginBottom: '100px' }}>Next: PoW Summary</button>
        </Link>
      </div>
    </div>
  );
};

export default Playground;

import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';
import CryptoJS from 'crypto-js';

const isPrime = (num) => {
  if (num <= 1) return false;
  if (num <= 3) return true;
  if (num % 2 === 0 || num % 3 === 0) return false;
  let i = 5;
  while (i * i <= num) {
    if (num % i === 0 || num % (i + 2) === 0) return false;
    i += 6;
  }
  return true;
};

const Playground = () => {
  const [boxValues, setBoxValues] = useState({
    box1: '',
    box2: '',
    box3: '',
    box4: '',
    box5: '',
    box6: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [privateKey, setPrivateKey] = useState(['', '', '', '', '']);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBoxValues({
      ...boxValues,
      [name]: value
    });
  };

  const multiplyNumbers = () => {
    const { box1, box2, box3, box4, box5 } = boxValues;
    const inputs = [parseInt(box1), parseInt(box2), parseInt(box3), parseInt(box4), parseInt(box5)];
  
    if (inputs.some(input => !isPrime(input))) {
      setErrorMessage('Please enter prime numbers only.');
      return;
    }
  
    const result = inputs.reduce((acc, curr) => acc * curr, 1);
    const combinedNumber = parseInt(result); // Convert the result to a single number
  
    // Convert combinedNumber to an array of numbers
    const updatedPrivateKey = combinedNumber
      .toString()
      .split('')
      .map(char => parseInt(char));
  
    setBoxValues({
      ...boxValues,
      box6: result
    });
    setErrorMessage('');
    setPrivateKey(updatedPrivateKey); // Update the private key
  
    // Clear the private key inputs
    setPrivateKey(['', '', '', '', '']);
    setInputText('')
  };
  
  
  const [inputText, setInputText] = useState('');
  const [encryptedText, setEncryptedText] = useState('');
  const [decryptedText, setDecryptedText] = useState('');
  const publicKey = boxValues.box6;

  const encrypt = () => {
    const ciphertext = CryptoJS.AES.encrypt(inputText.toString(), publicKey.toString()).toString();
    setEncryptedText(ciphertext);
  };

  const decrypt = () => {
    try {
      // Multiply the elements of the private key array to generate the private key
      const privateKeyProduct = privateKey.reduce((acc, curr) => acc * curr, 1);
  
      // Decrypt the message using the generated private key
      const bytes = CryptoJS.AES.decrypt(encryptedText, privateKeyProduct.toString());
      const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);
      
      if (!decryptedMessage) {
        throw new Error('Empty or invalid decrypted message');
      }
  
      setDecryptedText(decryptedMessage);
    } catch (error) {
      console.error('Error decrypting message:', error.message);
      setDecryptedText('Error: Incorrect private key');
    }
  };
 
  const handlePrivateKeyChange = (index, value) => {
    const updatedPrivateKey = [...privateKey]; // Copy the current private key array
    updatedPrivateKey[index] = value; // Update the value at the specified index
    setPrivateKey(updatedPrivateKey); // Update the state
  };

  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li>
          <li className="tab"><Link to="/research">Research</Link></li>
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li>
          <li className="tab"><Link to="/playground">Playground</Link></li>
        </ul>
      </div>

      <div className="playground">
        <h1>How is Cryptography Used to Encrypt and Exchange Data?</h1>
        <p>For our example let's encrypt a simple text message.</p>
        <div>
        <p>Similar to our <Link to="/playground/blockchain/cryptography/public-key-cryptography" style={{ textDecoration: 'underline' }}>first example</Link> let's first generate a public and private key:</p>
        </div>
        <div>
          <input type="number" name="box1" value={boxValues.box1} onChange={handleInputChange} />
          <span> X </span>
          <input type="number" name="box2" value={boxValues.box2} onChange={handleInputChange} />
          <span> X </span>
          <input type="number" name="box3" value={boxValues.box3} onChange={handleInputChange} />
          <span> X </span>
          <input type="number" name="box4" value={boxValues.box4} onChange={handleInputChange} />
          <span> X </span>
          <input type="number" name="box5" value={boxValues.box5} onChange={handleInputChange} />
          <span> = </span>
          <input type="text" name="box6" value={boxValues.box6} readOnly />
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <p></p>
        <button onClick={multiplyNumbers}>Generate</button>
        <p></p>
        <p>The public key will be used to encrypt and only the private key can decrypt.</p>
        <div>
          <p>Create any message that you will like that will be encrypted by the public key you just generated.</p>
          <input type="text" value={inputText} onChange={(e) => setInputText(e.target.value)} />
        </div>
        <div>
          <p></p>
          <button onClick={encrypt}>Encrypt</button>
          <p></p>
        </div>
        <div>
          <p>Below you will see your encrypted message:</p>
          <input type="text" value={encryptedText} readOnly />
        </div>
        <div>
        <div>
        <p>Now enter your private key (5 prime numbers) to decrypt the message:</p>
        <input type="number" value={privateKey[0] || ''} onChange={(e) => handlePrivateKeyChange(0, e.target.value)} />
        <input type="number" value={privateKey[1] || ''} onChange={(e) => handlePrivateKeyChange(1, e.target.value)} />
        <input type="number" value={privateKey[2] || ''} onChange={(e) => handlePrivateKeyChange(2, e.target.value)} />
        <input type="number" value={privateKey[3] || ''} onChange={(e) => handlePrivateKeyChange(3, e.target.value)} />
        <input type="number" value={privateKey[4] || ''} onChange={(e) => handlePrivateKeyChange(4, e.target.value)} />
        </div>
        </div>
        <div>
        <p></p>
        <button onClick={decrypt}>Decrypt</button>
        </div>
        <div>
        <p>Decrypted Message:</p>
        <input type="text" value={decryptedText} readOnly />
        </div>
        <p>As you play around with encrypting and decrypting you will notice that only the correct private key can decrypt the message.</p>
        <p>Let's debrief in our summary!</p>
        <p></p>
        <Link to="/playground/data-encryption-and-exchange/summary">
          <button>Next: Data Encryption and Exchange Summary</button>
        </Link>
      </div>
    </div>
  );
};

export default Playground;

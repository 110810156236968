import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>What is Proof of Work (PoW)?</h1>
          <p>Proof of Work (PoW) is a consensus mechanism used in blockchain networks to confirm transactions and achieve agreement on the state of the network.</p>
          <p>It was popularized by Bitcoin and is used in various other cryptocurrencies.</p>
          <p>In a PoW system, miners compete to solve complex mathematical puzzles, known as "hash functions," to validate and confirm transactions.</p>
          <p>These puzzles require significant computational power to solve but are easy to verify once a solution is found.</p>
          <p>Once a miner finds a valid solution, they broadcast it to the network.</p>
          <p>Other nodes in the network then verify the solution, ensuring that the block of transactions adheres to the rules of the network. </p>
          <p>If the solution is accepted by the majority of nodes, the block is added to the blockchain, and the miner is rewarded with newly minted cryptocurrency and transaction fees.</p>
          <p>I know that is a lot of information but let's use an example to show how this would work.</p>

          <Link to="/playground/consensus-mechanisms/proof-of-work-example">
          <button>Next: PoW Example</button>
            </Link>      
        </div>

    </div>

    );
};

export default Playground;
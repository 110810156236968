import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>What is Blockchain?</h1>
          <p>Blockchain is a decentralized, distributed ledger technology that enables secure and transparent record-keeping of transactions across a network of computers.</p>
          <p>That might not mean much to you now but it will once we learn the fundamentals.</p>
          <p>This section will help teach you what the glue and foundation of blockchain is based on: Cryptography</p>
          <Link to="/playground/blockchain/cryptography">
          <button>Next: Cryptography</button>
          </Link>      
      </div>
    </div>

    );
};

export default Playground;
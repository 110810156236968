import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>Proof of Work Summary</h1>
          <p>The primary purpose of PoW is to prevent double-spending and ensure the security and integrity of the blockchain network.</p>
          <p>It does so by making it computationally expensive to modify past transactions, as an attacker would need to redo the work of mining all subsequent blocks, which becomes increasingly difficult as the blockchain grows longer.</p>
          <p>In addition, a miner is rewarded for solving the puzzle with cryptocurrency.</p>
          <p>While PoW is effective in securing blockchain networks, it does have drawbacks:</p>
          <p>-High energy consumption due to the computational power required for mining</p>
          <p>-Potential for centralization of mining power in the hands of a few large mining pools</p>
          <p>As a result, alternative consensus mechanisms such as Proof of Stake (PoS) have been developed to address these issues.</p>
          <p>But, going back to our previous framework we determined there are three important components for a decentralized application to function:</p>
          <p>-Governance or the ability for all network users to participate in the network</p>
          <p>-Resource or the necessity for all users to provide value to the network, usually in the form of computational resource</p>
          <p>-Incentive or the reward given to users for participating and upholding the integrity of the network</p>
          <p>With Proof of Work, all three components are satisfied.</p>
          <p>Users participate in the network by connecting a node and using hardware to mine blocks which they are incentivized to do via a cryptocurrency reward.</p>
          <p>In essence, PoW is an effective decentralized network model.</p>
          <p></p>
          <Link to="/playground/">
          <button>Return to Playground</button>
          </Link>    
        </div>

    </div>

    );
};

export default Playground;
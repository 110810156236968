import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>What is a Decentralized Network?</h1>
          <p>So now that we know the power of cryptography an interesting opportunity becomes apparent.</p>
          <p>Since we can send data to each other securely and privately we no longer need to utilize an intermediary like a software provider.</p>
          <p>All we need is a distributed internet network to send this data to each other. And guess what, that already exists. We use it everyday!</p>
          <p>We can interact Peer-to-Peer (P2P) by using the internet. As long as our computers have internet connection, it is possible for me to communicate directly to your computer.</p>
          <p>So, we can stop using an intermediary like a sofware provider who stores our data and managers our hardware, and we can create our own P2P network.</p>
          <p>That is called a Decentralized Network!</p>
          <p>Let's illustrate this with an example!</p>
          <Link to="/playground/decentralized-network/example">
          <button>Next: Decentralized Network Example</button>
            </Link>      
        </div>

    </div>

    );
};

export default Playground;
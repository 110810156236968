import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>What is a Consensus Mechanism?</h1>
          <p>As discussed in the previous section there are three important components for a decentralized application to function:</p>
          <p>-Governance or the ability for all network users to participate in the network</p>
          <p>-Resource or the necessity for all users to provide value to the network, usually in the form of computational resource</p>
          <p>-Incentive or the reward given to users for participating and upholding the integrity of the network</p>
          <p>That is where Proof of Work (PoW) comes into play.</p>
          <p>We will first discuss PoW and then mention some other popular Consensus Mechanisms later.</p>

          <Link to="/playground/consensus-mechanisms/proof-of-work">
          <button>Next: Proof of Work (PoW)</button>
            </Link>      
        </div>

    </div>

    );
};

export default Playground;
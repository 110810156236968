import React from 'react';
import { Link } from 'react-router-dom';
import '../../index.css';

const Playground = () => {
  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li>
          <li className="tab"><Link to="/research">Research</Link></li>
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li>
          <li className="tab"><Link to="/playground">Playground</Link></li>
        </ul>
      </div>

      <div className="playground">
        <h1>Welcome to The Playground!</h1>
        <p>This is an educational resource that can help you learn more about various topics with a more integrated and hands-on learning experience.</p>
        <Link to="/playground/blockchain">
          <button className='learnBlockchain'>Learn About Blockchain!</button>
        </Link>
        <p></p>
        <Link to="/playground/data-encryption-and-exchange/">
          <button className='learnBlockchain'>Learn About Data Encryption and Exchange!</button>
        </Link>
        <p></p>
        <Link to="/playground/decentralized-network/">
          <button className='learnBlockchain'>Learn About Decentralized Networks!</button>
        </Link>
        <p></p>
        <Link to="/playground/consensus-mechanisms/">
          <button className='learnBlockchain'>Consensus Mechanisms</button>
        </Link>
      </div>
    </div>
  );
};

export default Playground;

import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import EnterpriseWP from '../pdf/whitepapers/Enterprise Blockchain Litepaper.pdf';
import InnovationChal from '../pdf/research essays/Innovation Challenge - Trash for Some, Treasure to Others.pdf';
import CFARC from '../pdf/research essays/CFARC_TEAM_BENCHMARK_FINAL.pdf';
import BENvsCON from '../pdf/frameworks/Benefit vs Constraint Framework.pdf';
import BlockchainBP from '../pdf/frameworks/Blockchain Coding Best Practices.pdf';
import WebScraping from '../pdf/frameworks/Framework for Building Web Scrapers.pdf';
import DNLpositions from '../pdf/frameworks/Framework for Opening DNL Positions - Capital.pdf';

const Research = () => {
  const showSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const allSections = document.querySelectorAll('.section2');
      allSections.forEach((sec) => {
        sec.style.display = 'none';
      });
      section.style.display = 'block';
    }
  };

  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>

        <div id="research" className="tab-content">
          <h2>Research</h2>
          <div className="sidebar">
            <button onClick={() => showSection('section1p2')}>Whitepapers</button>
            <button onClick={() => showSection('section2p2')}>Research Essays</button>
            <button onClick={() => showSection('section3p3')}>Frameworks</button>
          </div>
          <div className="content">
            <div id="section1p2" class="section2">
              <h3>Whitepapers</h3>
              <p><strong>Enterprise Blockchain Litepaper</strong></p>
              <p>Discover the revolutionary potential of enterprise blockchain solutions in this insightful litepaper. Offering a clear-eyed examination of its benefits, it illuminates how businesses can enhance security, transparency, and efficiency while fostering collaboration. Through real-world examples and practical insights, this litepaper is a great read for those seeking to stay ahead in the evolving landscape of modern business technology.</p>
              <p><a href={EnterpriseWP} target="_blank" rel="noreferrer">View Enterprise Blockchain Litepaper</a></p>
            </div>
            <div id="section2p2" class="section2" style={{ display: 'none' }}>
              <h3>Research Essays</h3>
              <p><strong>Wilbur-Ellis Innovation Award</strong></p>
              <p>The Wilbur-Ellis Innovation Award is a competition of Universities who can propose the most creative idea for providing food for more people. Paul and a team of students from DePaul University and University of Missouri created an idea of using Asian Carp as an alternative and cheap food source that can be added to Tom Brown porridge to help combat malnutrition in countries like Africa.</p>
              <p><a href={InnovationChal} target="_blank" rel="noreferrer">View Innovation Challenge Report</a></p>
              <p><strong>CFA Research Challenge</strong></p>
              <p>The CFA Research Challenge is an annual competition of University students creating equity research reports on a specific stock. Below is the report that Paul and his team of DePaul students created for TransUnion, earning a third place finish for DePaul University at the local regional Chicago competition in 2020.</p>
              <p><a href={CFARC} target="_blank" rel="noreferrer">View CFARC Final Report</a></p>
            </div>
            <div id="section3p3" class="section2" style={{ display: 'none' }}>
              <h3>Frameworks</h3>
              <p><strong>Benefit vs Constraint Framework</strong></p>
              <p>This simple yet important framework was built by Paul while running Taiga. There were a lot of projects that required various amounts of time and this framework was created to help determine if a project was worth the time. If the amount of time gained, so the benefit, is greater than the amount of time lost, the constraint, then a project was worthwhile.</p>
              <p><a href={BENvsCON} target="_blank" rel="noreferrer">View Benefit vs Constraint Framework</a></p>
              <p><strong>Blockchain Coding Best Practices</strong></p>
              <p>Specific to the ethers coding library, this framework discusses Paul's mot valuable lessons and best practices learned while building automated trading programs on various Ethereum dApps like Aave and Uniswap.</p>
              <p><a href={BlockchainBP} target="_blank" rel="noreferrer">View Blockchain Coding Best Practices</a></p>
              <p><strong>Framework for Building Web Scrapers</strong></p>
              <p>This framework highlights the most important factors and considerations when deciding whether a web scraper should be built for a certain process.</p>
              <p><a href={WebScraping} target="_blank" rel="noreferrer">View Framework for Building Web Scrapers</a></p>
              <p><strong>Framework for Opening New Delta Neutral LP Positions</strong></p>
              <p>This framework discusses the process of opening delta neutral liquidity pool positions. This was designed and implemented on v2 liquidity pools on Uniswap. The framework contains a step by step explanation of the model that Paul built to track key inputs and outputs for delta neutral positions.</p>
              <p><a href={DNLpositions} target="_blank" rel="noreferrer">View Framework for Opening New Delta Neutral LP Positions</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;

import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>What is Cryptography?</h1>
          <p>Cryptography is the practice and study of techniques for secure communication and data protection in the presence of adversaries.</p>
          <p>It involves the use of mathematical algorithms and principles to transform plaintext data into ciphertext in such a way that only authorized parties can decrypt and understand the original information.</p>
          <p>That all sounds great, but what does this have to do with blockchain?</p>
          <p>Well, blockchain technology relies on several cryptographic techniques to achieve its security objectives, including:</p>
          <p>Hash Functions</p>
          <p>Public-key Cryptography</p>
          <p>Digital Signatures</p>
          <p>Consensus Mechanisms</p>
          <p>Elliptic Curve Cryptography (ECC)</p>
          <p>And MORE...</p>
          <p>But, let's start with a type of cryptography that is commonly used in blockchain and other applications: Public-key Cryptography</p>

          <Link to="/playground/blockchain/cryptography/public-key-cryptography">
          <button>Next: Public-key Cryptography</button>
        </Link>      </div>

    </div>

    );
};

export default Playground;
import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>Data Encryption and Exchange Summary</h1>
          <p>So, what did we learn?</p>
          <p>We learned that a simple text message can be encrypted with a public key and only decrypted with the private key.</p>
          <p>Now, in real applications, we would use a more complex cryptography method, think more prime numbers.</p>
          <p>Public-key cryptography typically involves algorithms such as RSA or elliptic curve cryptography (ECC)</p>
          <p>In real use cases, we can encrypt and exchange more data types like payments, business information, numbers, etc.</p>
          <p>So, we learned that cryptography is used to prevent brute force attacks and it is used to encrypt and exchange data in a secure way.</p>
          <p>We can now take these principles and learn how they can be applied to create a decentralized network.</p>
          <Link to="/playground/">
          <button>Return to Playground</button>
          </Link>  
          <p></p>
          <Link to="/playground/decentralized-network/">
          <button>Continue to: Decentralized Networks</button>
          </Link>     
        </div>

    </div>

    );
};

export default Playground;
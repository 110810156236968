import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';

const NotFound = () => {
  return (
    <div>
    <div className="heading">
      <h1>Paul Paskevicius</h1>
      <p className="sub-heading">On Finance, Blockchain and more</p>
    </div>

    <div className="tabs-container">
      <ul className="tabs">
        <li className="tab"><Link to="/">About</Link></li>
        <li className="tab"><Link to="/research">Research</Link></li>
        <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li>
        <li className="tab"><Link to="/playground">Playground</Link></li>
      </ul>
    </div>
    
    <div className="playground">
        <h1>Error 404: Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <Link to="/">
          <button className='learnBlockchain'>Go back to Home</button>
        </Link>
    </div>
    </div>

  );
};

export default NotFound;

import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import '../../../index.css';

const Playground = () => {


  return (
    <div>
      <div className="heading">
        <h1>Paul Paskevicius</h1>
        <p className="sub-heading">On Finance, Blockchain and more</p>
      </div>

      <div className="tabs-container">
        <ul className="tabs">
          <li className="tab"><Link to="/">About</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/research">Research</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/bookcase">Paul's Bookcase</Link></li> {/* Use Link component for navigation */}
          <li className="tab"><Link to="/playground">Playground</Link></li> {/* Use Link component for navigation */}
        </ul>
      </div>

      <div className="playground">
          <h1>Putting Cryptography and Blockchain Together</h1>
          <p>Cryptography is the oil that lubricates the blockchain engine.</p>
          <p>We will dive deeper into the nuances and design choices of various types of blockchains later on.</p>
          <p>But, one key takeaway is that cryptography sets the foundation for everything that we learn about next.</p>
          <p>So, let's recap this section.</p>
          <p>You learned about one of the many type of cryptographic techniques that are used:</p>
          <p>Hash Functions</p>
          <p>Public-key Cryptography</p>
          <p>Digital Signatures</p>
          <p>Consensus Mechanisms</p>
          <p>Elliptic Curve Cryptography (ECC)</p>
          <p>And MORE...</p>
          <p>Specifically, you learned how Public-key Cryptography uses math to encrypt a message.</p>
          <p>Now, we will learn how that encryption can allow the message to become any type of data like a payment, a transaction, a private message and more.</p>
          <p>And ultimately, we will learn how that data becomes a fully distributed and decentralized blockchain network.</p>
          <p></p>
          <Link to="/playground/">
          <button>Return to Playground</button>
          </Link>  
          <p></p>
          <Link to="/playground/data-encryption-and-exchange/">
          <button>Continue to: Data Encryption and Exchange</button>
          </Link>  
        </div>
    </div>

    );
};

export default Playground;